import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

const ChatSupport = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // State to toggle chat box visibility
  const chatHistoryRef = useRef(null); // Reference to the chat history div

  const handleInputChange = (e) => setInput(e.target.value);

  // Define custom responses for specific questions
  const customResponses = {
    "hello": "hi there! How can I assist you today?",
    "how are you": "I'm here to assist you. How can I help today?",
    "how r u": "I'm here to assist you. How can I help today?",
    "what is your name": "I'm your support assistant. How can I assist you today?",
    // Add more predefined responses here as needed
  };

  const getCustomResponse = (message) => {
    // Check if the message matches any of the predefined responses
    const lowerCaseMessage = message.toLowerCase();
    return customResponses[lowerCaseMessage] || null;
  };

  const sendMessage = async () => {
    if (input.trim() === "") return;
    const userMessage = input.trim();

    // Add user message to chat
    setMessages((prevMessages) => [
      ...prevMessages,
      { id: uuidv4(), sender: "user", text: userMessage },
    ]);
    setInput("d");
    setIsTyping(true);

    // Check if the message matches any predefined response
    const customResponse = getCustomResponse(userMessage);
    if (customResponse) {
      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { id: uuidv4(), sender: "bot", text: customResponse },
        ]);
        setIsTyping(false);
      }, 500); // Adding a slight delay for realism
      return;
    }

    // Call OpenAI API if no custom response is available
    try {
      const apiKey = "sk-i6hIcCk_6z9ZG0sKObGKvo-kDZDyeQI2DA2VNUZURBT3BlbkFJ0AGdIGZNmkJuzBpzokad3T9Nf0UUOeZCzMxROXumkA"; // Use environment variable
      // Call OpenAI API directly from the frontend
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o',
          messages: [{ role: 'user', content: userMessage }],
          max_tokens: 150,
          temperature: 0.7,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`, // Use environment variable
          },
        }
      );

      const botReply = response.data.choices[0].message.content.trim();
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: uuidv4(), sender: "bot", text: botReply },
      ]);
    } catch (error) {
      console.error("Error communicating with OpenAI:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: uuidv4(), sender: "bot", text: "Sorry, something went wrong. Please try again later." },
      ]);
    } finally {
      setIsTyping(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  // Scroll to the bottom of the chat history whenever messages change
  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="chat-support">
      {/* Chat Icon */}
      <div className="chat-icon" onClick={() => setIsOpen(!isOpen)}>
        💬
      </div>

      {/* Chat Box */}
      <div className={`chat-box ${isOpen ? 'open' : ''}`}>
        <div className="chat-header">
        <img src={require('../assets/logo/logo.png')} alt="logo" /> 
        <hr/>
        <h4>Support Chat</h4>
        </div>
        <div className="chat-history" ref={chatHistoryRef}>
          {messages.map((message) => (
            <div key={message.id} className={`chat-message ${message.sender}`}>
              {message.text}
            </div>
          ))}
          {isTyping && <div className="chat-message bot">Typing...</div>}
        </div>
        <div className="chat-input">
          <input
            type="text"
            value={input}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Type your message..."
          />
          <button onClick={sendMessage}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default ChatSupport;
