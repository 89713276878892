import React, { useState, useEffect } from "react";
import { Alert, Button, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import SideMenu from "../SideMenuComponent/SideMenu";
import { BiSolidCartAlt, BiSolidLeftArrow, BiSolidLeftArrowSquare } from "react-icons/bi";

export const Main = () => {
  const { pathname } = window.location;
  const id = pathname.split("/").pop();

  // Generate a random user ID if not already present in session storage
  const generateUserId = () => {
    const randomUserId = Math.floor(Math.random() * 1000000);
    sessionStorage.setItem("randomUserId", randomUserId);
    return randomUserId;
  };

  const userId = sessionStorage.getItem("userId") || sessionStorage.getItem("randomUserId") || generateUserId();
  const isLoggedIn = sessionStorage.getItem("userId") !== null;

  // State for products and variations
  const [coffee, setProducts] = useState([]);
  const [variation, setVariation] = useState([]);
  const [selectedProductVariations, setSelectedProductVariations] = useState(null);
  const [num, setNum] = useState(0);
  const [show, setShow] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [cartItems, setCartItems] = useState([]); // State for cart items

  const handleToggleClick = () => {
    setShowSideMenu(!showSideMenu);
  };

  // Fetch product data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE";
        const response = await axios.get(
          `https://ecom.testingwebsitelink.com/api/getproduct/${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setProducts(response.data.data);
        setVariation(response.data.data.product_variations);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  // Handle quantity change
  const incNum = () => setNum((prevNum) => Math.min(prevNum + 1, 10));
  const decNum = () => setNum((prevNum) => Math.max(prevNum - 1, 0));
  const handleChange = (e) => setNum(e.target.value);

  // Handle product variation select
  const handleProductVariationSelect = (variation) => {
    setSelectedProductVariations(variation);
  };

  // Handle add to cart
  const handleAddToCart = async () => {
    try {
      const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk";
      await axios.post(
        "https://ecom.testingwebsitelink.com/api/addtocart",
        {
          product_id: selectedProductVariations.product_id,
          variation_id: selectedProductVariations.id,
          quantity: num,
          user_id: userId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success("Added to Cart Successfully");
      setCartItems((prevCartItems) => [
        ...prevCartItems,
        { ...selectedProductVariations,
          title: coffee.title,
          feature_image: coffee.feature_image,
          quantity: num,
          variation_total_price: num * selectedProductVariations.price 
        },
      ]);

      setShowSideMenu(true);
    } catch (error) {
      toast.error("Error adding to cart:", error);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Function to remove HTML tags from a string
  const removeTags = (str) => {
    var tempElement = document.createElement("div");
    tempElement.innerHTML = str;
    var cleanedString = tempElement.innerHTML.replace(/<p[^>]*>/g, '');
    cleanedString = cleanedString.replace(/<\/?[^>]+(>|$)/g, '');
    return cleanedString;
  };

  // Close side menu when clicking anywhere on the body
  const handleBodyClick = (event) => {
    if (showSideMenu && !event.target.closest('.side-menu')) {
      setShowSideMenu(false);
    }
  };

  useEffect(() => {
    // Add event listener on mount
    document.addEventListener('click', handleBodyClick);

    // Remove event listener on unmount
    return () => {
      document.removeEventListener('click', handleBodyClick);
    };
  }, [showSideMenu]);

  return (
    <section className="main productPage">
      <SideMenu isOpen={showSideMenu} toggleMenu={handleToggleClick} cartItems={cartItems} />
      <div className="arrowRight"><BiSolidCartAlt onClick={() => handleToggleClick()} />{cartItems.length > 0 && <span className="cartItemCount">{cartItems.length}</span>}</div>

      <div className="container">
        <div className="row mt-4">
          <div className="col-md-6">
            <div className="productBox">
              <img src={coffee.feature_image} alt="product" />
            </div>
          </div>
          <div className="col-md-6">
          {coffee.stock == 0 ? (
              <div class="alert alert-dangerStock h5 text-center" role="alert">
              Out Of Stock
             </div>
            ) : (
              null
            )}
            <div className="productBoxDescription">
              <div className="productSubsButton">
                <button className="singleButton">
                  Single Purchase
                  <br />
                  <span>$ {coffee.price} per bag + shipping</span>
                </button>
                {userId || isLoggedIn ? (
                  <Link to={isLoggedIn ? "/subscription" : "/login"}
                  //  onClick={isLoggedIn ? null : handleShow}
                   >
                    <button className="subsButton">
                      Subscription Service
                      <br />
                      <span>Up to 15% Discount</span>
                    </button>
                  </Link>
                ) : (
                  <Link to="/login" 
                  // onClick={handleShow}
                  >
                    <button className="subsButton">
                      Subscription Service
                      <br />
                      <span>Up to 15% Discount</span>
                    </button>
                  </Link>
                )}
                <Modal className='popupMsg' show={show} onHide={handleClose}>
                  <Modal.Header className='bg-danger text-white' closeButton>
                    <Modal.Title>Alert</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>You have to login to buy a subscription!</Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Link to="/login">
                      <Button className='btn btn-color-login border-0 text-black' variant="primary" onClick={handleClose}>Login</Button>
                    </Link>
                  </Modal.Footer>
                </Modal>
              </div>
              <div className="writterDescription mt-4">
                      <h2>{coffee.title === "Ground Beans" && "Ground Coffee"}</h2>
                      <h2>{coffee.title === "Whole Beans" && "Whole Coffee"}</h2>
                      <h2>{coffee.title === "K-Cups" && "K-Cups"}</h2>
                {/* <p>{removeTags(coffee.description)}</p> */}
                
              </div>
              <div className="row mt-4">
                {variation.map((variation) => (
                  <div className="col-lg-4 col-md-6 col-12" key={variation.id}>
                    <div
                      className="productBoxOne"
                      style={selectedProductVariations && selectedProductVariations.id === variation.id
                        ? { backgroundColor: "#48412D" }
                        : { cursor: "pointer" }}
                      onClick={() => handleProductVariationSelect(variation)}
                    >
                      <div className="imgBox">
                        <img src={variation.image} alt="product" />
                      </div>
                      <div className="productDescriptionOne">
                        <h3>{variation.variation}</h3>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {selectedProductVariations && (
                <div className="row mt-4 selectedProduct">
                  <div className="col-lg-4 col-md-6 col-4">
                    <div className="imgBox">
                      <img src={selectedProductVariations.image} alt="selected product" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-8">
                    <div className="textsec">
                      <h4>{selectedProductVariations.variation}</h4>
                      <p>{selectedProductVariations.stock}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="price">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <button className="btn" type="button" onClick={decNum}>-</button>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          value={num}
                          onChange={handleChange}
                        />
                        <div className="input-group-prepend">
                          <button className="btn" type="button" onClick={incNum}>+</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row mt-4">
                <div className="col-md-12">
                  <h2 style={{ textAlign: "left", color: "white", fontSize: "32px" }}>Order Total</h2>
                </div>
              </div>
              <Row className="detailsOfSelectedProduct">
                <div className="col">
                  <h4>Texture type</h4>
                </div>
                <div className="col">
                  <h5>{coffee.title === "Ground Beans" && "Ground Coffee"}
                      {coffee.title === "Whole Beans" && "Whole Coffee"}
                      {coffee.title === "K-Cups" && "K-Cups"}   
                      {/* ${selectedProductVariations ? selectedProductVariations.sale_price : "00.00"} */}
                      </h5>
                </div>
              </Row>
              <Row className="detailsOfSelectedProduct">
                <div className="col">
                  <h4>Coffee Type</h4>
                </div>
                <div className="col">
                  <h5>{selectedProductVariations ? selectedProductVariations.variation : "--"}</h5>
                </div>
              </Row>
              <Row className="detailsOfSelectedProduct">
                <div className="col">
                {coffee.title == 'K-Cups' ? (
                    <h4>Coffee Cups</h4>
                  ) : (
                  <h4>Coffee Bags</h4>
                  )}
                </div>
                <div className="col coffee-bag-quantity">
                  <input
                    type="text"
                    className="coffee-bag-quantity-div"
                    value={num}
                    onChange={handleChange}
                  />
                   {/* {coffee.title == 'K-Cups' ? (
                    <h4 style={{margin:'auto'}}>Cups</h4>
                  ) : (
                  <h4 style={{margin:'auto'}}>Bags</h4>
                  )} */}
                  {/* <h5>bags</h5> */}
                </div>
              </Row>
              {/* <Row className="detailsOfSelectedProduct">
                <div className="col">
                  <h4>Shipping cost</h4>
                </div>
                <div className="col">
                  <h5>$0.00</h5>
                </div>
              </Row> */}
              <Row className="detailsOfSelectedProduct">
                <div className="col">
                  <h4 className="fnlDetails">Order Total</h4>
                </div>
                <div className="col">
                  <h5 className="fnlDetails">
                    ${selectedProductVariations ? num * selectedProductVariations.sale_price : 0}
                  </h5>
                </div>
              </Row>
            </div>
            {coffee.stock == 0 ? (
              null
            ) : (
              <>
              {num === 0 ? (
              <button className="step4Button disabled w-100 my-3">
                Add to Cart
              </button>
            ) : (
              <button className="addCartButton w-100 my-3 addtocartButton" onClick={handleAddToCart}>
                Add to Cart
              </button>
            )}
              </>

            )}
            
          </div>
        </div>
      </div>
    </section>
  );
};
