import React from 'react'
import { Banner } from '../Components/PrivacyPolicyComp/Banner'
import { SecondBanner } from '../Components/PrivacyPolicyComp/SecondBanner'

const PrivacyPloicy = () => {
  return (
    <>
      <Banner/>
      <SecondBanner/>
    </>
  )
}

export default PrivacyPloicy
