import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const Main = () => {
    const navigate = useNavigate();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [formData, setFormData] = useState({
        email: "",
        name: "",
        subject: "",
        message: ""
    });
    const [errorMessage, setErrorMessage] = useState("");
    const handleContact = async (event) => {
        event.preventDefault();
        try {
            const token = "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE";
            const response = await axios.post(
                "https://ecom.testingwebsitelink.com/api/sendEmail", // Updated endpoint to "register"
                {
                    email: formData.email,
                    name: formData.name,
                    subject: formData.subject,
                    message: formData.message
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setIsSubmitted(true);
            console.log("Form Sended:", response.data);
            // navigate("/login");
            // window.location.reload();
        } catch (error) {
            setIsSubmitted(false);
            console.error("Error Form Sending:", error);
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
  return (
    <>
    <section className="contact-section">
        <div className="container">
            <div className="row bordercolor">
                <div className="col-lg-6">
                <div className="columnMap">
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d443088.05180804833!2d-95.7309486035178!3d29.817364684331217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640b8b4488d8501%3A0xca0d02def365053b!2sHouston%2C%20TX%2C%20USA!5e0!3m2!1sen!2s!4v1713467857952!5m2!1sen!2s" className='mapiFrame' width="600" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                    <img style={{objectFit:'contain',width:'100%',borderRadius:'10px'}} src={require('../assets/images/Map.png')} alt="" /> 
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="contact-form">
                        <h2 className='slug'>Get In Touch</h2>
                        {isSubmitted ? (
        <div style={{textAlign:'left'}}>
          <h2 className='text-white'>Thank you for reaching out!</h2>
          <p className='text-white h6'>We have received your message and will respond as soon as possible. We appreciate your patience in the meantime.</p>
        </div>
      ) : (
                        <form id="contact-form" method="post" onSubmit={handleContact}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <input type="text" name="name" value={formData.name}
                                onChange={handleChange} placeholder="Your Name" />
                                </div>
                                <div className="col-lg-12">
                                    <input type="email" name="email" value={formData.email}
                                onChange={handleChange} placeholder="Your Email" />
                                </div>
                                <div className="col-lg-12">
                                    <input type="text" name="subject" value={formData.subject}
                                onChange={handleChange} placeholder="Subject" />
                                </div>
                                <div className="col-lg-12">
                                    <textarea name="message" rows={5} value={formData.message}
                                onChange={handleChange} placeholder="Your Message"></textarea>
                                </div>
                                <div className="col-lg-12 text-right contactButton">
                                    <button type="submit" className="site-btn">Send Message</button>
                                </div>
                            </div>
                        </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
