import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure to import Bootstrap CSS
import { Tab, Tabs } from "react-bootstrap";
import Main from "../RegisterComponent/Main";
import { toast } from "react-toastify";

const Login = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const userId = sessionStorage.getItem("userId");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);
  };
  const handleClose = () => setShowPopup(false);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const token = "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE";
      const response = await axios.post(
        "https://ecom.testingwebsitelink.com/api/customer/login",
        {
          email: formData.email,
          password: formData.password
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Login response:", response.data);
      console.log("userId:", response.data.data.id);
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("randomUserId");
      // Save username or ID in session storage
      sessionStorage.setItem("userId", response.data.data.id);
      sessionStorage.setItem("userToken", response.data.data.token);
      // Redirect to home page
      navigate("/");
      toast.success("Login successful!");
      // window.location.reload();

    } catch (error) {
      if (error.response.data.message == 'OTP Not Verified') {
        navigate(`/verify-user-otp/${formData.email}`);
      } else {
        handleShow("Invalid email or password. Please try again."); // Update message as needed
      }
      console.error("Error logging in:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    if (!userId) {
      return ;
    } else {
      navigate("/");
      }
  })
  return (
    <>
     <div className="container Auth-form-container">
    <div className="Auth-form-header">
        <div className="Auth-form-header-text">
          <h1>JOIN THE <span>CLUB</span></h1>
          <ul>
            <li>
            -Premium specialty coffee
            </li>
            <li>
            -Discounts  and customizable delivery schedule
            </li>
            <li>
            -Donations towards veterans mental health foundations
            </li>
          </ul>
        </div>
      </div>
    <div className="Auth-form">
     
    <Tabs
      defaultActiveKey="profile"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Register">
        <Main/>
      </Tab>
      <Tab eventKey="profile" title="Login">
     
       
       <form onSubmit={handleLogin}>
           <h3 className="Auth-form-title">SIGN IN</h3>
         <div className="Auth-form-content">
           <div className="form-group mt-3">
             {/* <label>Email address</label> */}
             <input
               type="email"
               name="email"
               className="form-control mt-1"
               placeholder="Enter email address"
               value={formData.email}
               onChange={handleChange}
               required
             />
           </div>
           <div className="form-group mt-3 position-relative">
                <input
                  type={showPassword ? "text" : "password"} // Toggle input type
                  name="password"
                  className="form-control mt-1"
                  placeholder="Enter password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <span
                  className="position-absolute top-50 end-0 translate-middle-y pe-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowPassword((prev) => !prev)} // Toggle visibility
                >
                  {showPassword ? (
                    <i className="bi bi-eye-slash"></i> // Eye slash icon for hide
                  ) : (
                    <i className="bi bi-eye"></i> // Eye icon for show
                  )}
                </span>
              </div>
           <p className="text-white mt-2">
           <Link to="/forget-password">Forgot password?</Link>
           </p>
           <div className="d-grid gap-2 mt-3">
             <button type="submit" className="btn btn-color-login">
               LOGIN
             </button>
           </div>
           
           {/* <div className="text-center">
             Not registered?{" "}
             <Link className="login-link-color" to="/register">
               Sign Up
             </Link>
           </div> */}
         </div>
       </form>
       <Modal show={showPopup} onHide={handleClose}>
         <Modal.Header closeButton>
           <Modal.Title>Error</Modal.Title>
         </Modal.Header>
         <Modal.Body>{errorMessage}</Modal.Body>
       </Modal>
      </Tab>
    </Tabs>
    </div>
    </div>    
    </>
  );
};

export default Login;
