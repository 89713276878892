import React, { useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure to import Bootstrap CSS
import { Tab, Tabs } from "react-bootstrap";
import Main from "../RegisterComponent/Main";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";

const VerifyUserOtp = () => {
    const { pathname } = window.location;
    const emailforverify = pathname.split("/").pop();
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    otp: "",
    password: "",
  });

  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);
  };
  const handleClose = () => setShowPopup(false);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://ecom.testingwebsitelink.com/api/customer/otpVerify",
        {
            otp: formData.otp,
          email: emailforverify,
        }
      );
      // Redirect to home page
      navigate(`/login`);
    //   window.location.reload();
      toast.success("OTP Verified. Please enter new password"); // Update message as needed
    } catch (error) {
      console.error("Error logging in:", error);
      handleShow("Invalid email or password. Please try again."); // Update message as needed
    }
  };
  const handleResend = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://ecom.testingwebsitelink.com/api/customer/resendOtp",
        {
          email: emailforverify,
        }
      );
      toast.success("OTP sent to your email address"); // Update message as needed
    } catch (error) {
      console.error("Error logging in:", error);
      handleShow("Invalid email or password. Please try again."); // Update message as needed
    }
  };

  const handleChange = (otp) => {
    setFormData({
      ...formData,
      otp, // Update the `otp` field directly
    });
  };
  return (
    <>
     <div className="container Auth-form-container">
    <div className="Auth-form-header">
        <div className="Auth-form-header-text">
          <h1>JOIN THE <span>CLUB</span></h1>
          <ul>
            <li>
            -Premium specialty coffee
            </li>
            <li>
            -Discounts  and customizable delivery schedule
            </li>
            <li>
            -Donations towards veterans mental health foundations
            </li>
          </ul>
        </div>
      </div>
    <div className="Auth-form">
     
       
       <form onSubmit={handleLogin}>
           <h3 className="Auth-form-title">Verify OTP</h3>
         <div className="Auth-form-content">
           <div className="form-group mt-3">
             {/* <label>Email address</label> */}
             <input
               type="email"
               name="email"
               className="form-control mt-1"
               placeholder="Enter email address"
               value={emailforverify}
               disabled
               required
             />
           </div>
           <div className="form-group otp mt-3">
             <OTPInput
               name="otp"
               id="otp"
               value={formData.otp}
               onChange={handleChange}
      numInputs={5}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
    />
           </div>
           <p className="text-white mt-2" style={{cursor:'pointer'}} onClick={handleResend}>
        Resend password?
           </p>
           <div className="d-grid gap-2 mt-3">
             <button type="submit" className="btn btn-color-login">
               Verify
             </button>
           </div>
         </div>
       </form>
       
    </div>
    </div>    
    </>
  );
};

export default VerifyUserOtp;
