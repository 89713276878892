import React, { useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure to import Bootstrap CSS
import { Tab, Tabs } from "react-bootstrap";
import Main from "../RegisterComponent/Main";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);
  };
  const handleClose = () => setShowPopup(false);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://ecom.testingwebsitelink.com/api/customer/sendOtpRsetPassword",
        {
          email: formData.email,
        },
      );
      // Redirect to home page
      navigate(`/verify-otp/${formData.email}`);
      toast.success("OTP sent to your email address"); // Update message as needed
    } catch (error) {
      console.error("Error logging in:", error);
      handleShow("Invalid email or password. Please try again."); // Update message as needed
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
     <div className="container Auth-form-container">
    <div className="Auth-form-header">
        <div className="Auth-form-header-text">
          <h1>JOIN THE <span>CLUB</span></h1>
          <ul>
            <li>
            -Premium specialty coffee
            </li>
            <li>
            -Discounts  and customizable delivery schedule
            </li>
            <li>
            -Donations towards veterans mental health foundations
            </li>
          </ul>
        </div>
      </div>
    <div className="Auth-form">
     
       
       <form onSubmit={handleLogin}>
           <h3 className="Auth-form-title">Forget Password</h3>
         <div className="Auth-form-content">
           <div className="form-group mt-3">
             {/* <label>Email address</label> */}
             <input
               type="email"
               name="email"
               className="form-control mt-1"
               placeholder="Enter email address"
               value={formData.email}
               onChange={handleChange}
               required
             />
           </div>
          
           <div className="d-grid gap-2 mt-3">
             <button type="submit" className="btn btn-color-login">
               Send OTP
             </button>
           </div>
           
           {/* <div className="text-center">
             Not registered?{" "}
             <Link className="login-link-color" to="/register">
               Sign Up
             </Link>
           </div> */}
         </div>
       </form>
       
    </div>
    </div>    
    </>
  );
};

export default ForgetPassword;
