import React from 'react'

export const SecondBanner = () => {
  return (
    <>
    <section className="main SecondAboutSec">
        <div className="container my-4">
            <div className="row align-items-center">
                <div className="col-md-6">
                    <h2>OUR MISSION</h2>
                    <p>
                    First Call Coffee Company (FCCC) was founded with the goal to provide high quality single origin specialty-grade coffee. FCCC carefully selected only a few green coffee bean varieties to preserve consistency and quality. The high-quality convection air roaster enhances flavor notes and aroma. Green coffee beans are hand-picked for optimum selection in high altitude subtropical climate. Premium small batch roasting provides a smooth and fresh coffee in every cup.
                    </p>
                </div>
                <div className='col-md-6'>
                    <img style={{objectFit:'contain',width:'100%'}} src={require('../assets/images/aboutus.png')}  alt=''/>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
