import React from 'react'
import { Banner } from '../Components/TermsAndConditionComp/Banner'
import { SecondBanner } from '../Components/TermsAndConditionComp/SecondBanner'

const TermsAndCondition = () => {
  return (
    <>
      <Banner/>
      <SecondBanner/>
    </>
  )
}

export default TermsAndCondition
