import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe('pk_test_51HiCo6EHLDkHxi1YwwTc185yQTBuRIZktAiqLEus7vFq1kKxsrir4UlAUVCP6rRokopLAFCYY1DKowhrjZuLhyv200gfW8PqZc');

const PaymentForm = ({ selectedOrderSummry }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const userId = sessionStorage.getItem("userId");

  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: { email },
    });

    if (error) {
      setError(error.message);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        'https://ecom.testingwebsitelink.com/api/payment',
        {
          // user_id: userId,
          id: selectedOrderSummry.id,
          paymentMethodId: paymentMethod.id,
          amount: selectedOrderSummry.final_amount,
        }
      );

      console.log('Payment Response:', response.data);
      toast.success('Payment successful');
      navigate('/')
    } catch (error) {
      console.error('Error making payment:', error);
      toast.error('Payment failed');
    }

    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      {/* <Form.Group controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </Form.Group> */}
       <div className="card-element-container">
        <CardElement options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#ffffff',
              '::placeholder': {
                color: '#aab7c4',
              },
              padding: '10px 12px',
              border: '1px solid #ced4da',
              borderRadius: '4px',
              backgroundColor: 'transparent',
              boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
            },
            invalid: {
              color: '#9e2146',
            },
          },
          hidePostalCode: true,
        }} />
      </div>
      <button className='proceedCheckout3 mt-2' type="submit" disabled={!stripe || loading}>
        {loading ? 'Loading...' : 'Pay Now'}
      </button>
      {error && <div>{error}</div>}
    </Form>
  );
};


export const Main = () => {
  const [selectedOrderSummry, setSelectedOrderSummry] = useState([]);
  const [productVariations, setProductVariations] = useState([]);
  const [productSummary, setProductSummary] = useState([]);
  const [packageSummary, setPackageSummary] = useState([]);

  useEffect(() => {
 

    const { pathname } = window.location;
    const id = pathname.split("/").pop();
    const getOrderSummary = async () => {
      try {
        const token = "OyZCWiISTQ6LMQBt71eC7TpwRwIThjb0osp9VVFk";
        const response = await axios.get(
          `https://ecom.testingwebsitelink.com/api/subscriptionOrder/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSelectedOrderSummry(response.data.data);
        setProductVariations(response.data.data.product_variations);
        setProductSummary(response.data.data.product);
        setPackageSummary(response.data.data.package);
        console.log('product_summary',response.data.data.product);
        console.log('product_variations',response.data.data.product_variations);
        console.log('setSelectedOrderSummry',response.data.data);
      } catch (error) {
        console.error("Error fetching product variations:", error);
        toast.error("Error fetching product variations");
      }
    };
    
    getOrderSummary();
    // getOrderSummary();
  }, []); // Empty dependency array ensures this effect runs only once
  return (
    <>
      <section className="sectionPayment">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="paymentForm">
                <div className="checkoutCol">
                  <h2>Pay with Stripe</h2>
                  <div className="row columnreverse">
                    <div className="col-lg-6">
                    <div className="formCheckout">
                    <h4>Credit or Debit card</h4>
                    <h4>Your payements are secured, Your Details are confedentials</h4>
                    {/* <Form>
                      <Row>
                        <Col sm="12" lg="12" xl="12">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicCNum"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Card Number"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm="6" lg="6" xl="6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEDate"
                          >
                            <Form.Control
                              type="number"
                              placeholder="Expiration Date"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm="6" lg="6" xl="6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicSCode"
                          >
                            <Form.Control
                              type="number"
                              placeholder="Security Code"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm="6" lg="6" xl="6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicFName"
                          >
                            <Form.Control
                              type="text"
                              placeholder="First Name"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm="6" lg="6" xl="6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicLName"
                          >
                            <Form.Control
                              type="number"
                              placeholder="Last Name"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm='6' lg='6' xl='6'>
                        <Form.Group className="mb-3">
        <Form.Check
          required
          label="Remember this card for the future"
          feedback="You must agree before submitting."
          feedbackType="invalid"
        />
      </Form.Group>
                        </Col>
                        <Col lg='12'>
                          <button className="w-100 py-2 btnPayment">
                          Confirm & Pay
                          </button>
                        </Col>
                      </Row>
                    </Form> */}
                    <Elements stripe={stripePromise}>
                        <PaymentForm selectedOrderSummry={selectedOrderSummry} />
                      </Elements>
                  </div>
                  
                    </div>
                    <div className="col-lg-6">
                      <div className="paymentProduct">
                        <h2>
                        Subscription total
                        </h2>
                        <Row className="detailsOfSelectedProduct mt-4">
                      <div className="col">
                        <h4>Coffee type</h4>
                      </div>
                      <div className="col">
                      <h5>{productSummary.title}</h5>
                      </div>
                    </Row>
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4>Flavor</h4>
                      </div>
                      {/* <div className="col">
                        <h5>testy coffe stimy</h5>
                      </div> */}
                      <div className="col">
                      {productVariations.map((roastType, index) => (
                            <h5 key={index}>{roastType.variation} - {roastType.quantity} Bags</h5>
                          ))}
                      </div>
                    </Row>
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4>Coffee Bags</h4>
                      </div>
                      <div className="col">
                        <h5>{selectedOrderSummry.total_quantity}bags</h5>
                      </div>
                    </Row>
                    {/* <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4>Shipping Frequency</h4>
                      </div>
                      <div className="col">
                        <h5>{selectedOrderSummry.package.number_of_days} Days</h5>
                      </div>
                    </Row> */}
                    {/* Add more rows for other selected details */}
                    <Row className="detailsOfSelectedProduct">
                      <div className="col">
                        <h4>Shipping Cost</h4>
                      </div>
                      <div className="col">
                        <h5>$ {selectedOrderSummry.shipping_amount}</h5>
                      </div>
                    </Row>
                    <Row className="detailsOfSelectedProduct" style={{marginTop:'40px'}}>
                      <div className="col">
                        <h3>Order Total</h3>
                      </div>
                      <div className="col">
                        <h5>$ {selectedOrderSummry.final_amount}</h5>
                      </div>
                    </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
