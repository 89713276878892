import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

const NewPassword = () => {
  const { pathname } = window.location;
  const emailforverify = pathname.split("/").pop();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await axios.post(
        "https://ecom.testingwebsitelink.com/api/customer/resetPassword",
        {
          new_password: formData.password,
          email: emailforverify,
        }
      );
      navigate("/login");
      toast.success("Password reset successful. Please login with your new password.");
    } catch (error) {
      console.error("Error resetting password:", error);
      toast.error("Error resetting password. Please try again.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <div className="container Auth-form-container">
        <div className="Auth-form-header">
          <div className="Auth-form-header-text">
            <h1>
              JOIN THE <span>CLUB</span>
            </h1>
            <ul>
              <li>-Premium specialty coffee</li>
              <li>-Discounts and customizable delivery schedule</li>
              <li>-Donations towards veterans mental health foundations</li>
            </ul>
          </div>
        </div>
        <div className="Auth-form">
          <form onSubmit={handleLogin}>
            <h3 className="Auth-form-title">New Password</h3>
            <div className="Auth-form-content">
              <div className="form-group mt-3">
                <input
                  type="email"
                  name="email"
                  className="form-control mt-1"
                  placeholder="Enter email address"
                  value={emailforverify}
                  disabled
                  required
                />
              </div>
              <div className="form-group mt-3 position-relative">
                <input
                  type={showPassword ? "text" : "password"} // Toggle input type
                  name="password"
                  className="form-control mt-1"
                  placeholder="Enter password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <span
                  className="position-absolute top-50 end-0 translate-middle-y pe-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowPassword((prev) => !prev)} // Toggle visibility
                >
                  {showPassword ? (
                    <i className="bi bi-eye-slash"></i> // Eye slash icon for hide
                  ) : (
                    <i className="bi bi-eye"></i> // Eye icon for show
                  )}
                </span>
              </div>
              <div className="d-grid gap-2 mt-3">
                <button type="submit" className="btn btn-color-login">
                  Reset Password
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewPassword;
