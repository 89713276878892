import React from 'react'

export const SecondBanner = () => {
  return (
    <>
    <section className="main SecondAboutSec">
        <div className="container my-4">
            <div className="row align-items-center">
                <div className="col-md-12">
                    <h2>Privacy Policy</h2>
                    <h3><b>Effective Date: </b> 11/10/24</h3>
                    <p>
                    First Call Coffee Company, LLC we values your privacy and is committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and protect information that we collect from customers, website visitors, and users of our services.
                    </p>
                    <h3><b>1. Information We Collect</b></h3>
                    <p>
                    We collect information to provide better services to our customers. The types of personal information we may collect include:
                    </p>
                    <h3>1.1<b> Personal Identification Information: </b><span>
                    Name, email address, mailing address, phone number, and payment details.
                    </span></h3>
                    <p>
                    
                    </p>
                    <h3>1.2 <b> Order and Transaction Information: </b><span>
                    Details related to purchases, subscriptions, and order history.
                    </span></h3>
                    <h3>1.3 <b> Technical Data: </b><span>
                    Information about your browser, IP address, device type, operating system, and browsing behavior when you visit our website.
                    </span></h3>
                    <h3>1.4  <b> Marketing Preferences: </b><span>
                    Information about your preferences for receiving marketing communications and surveys.
                    </span></h3>
                    <h3>1.5  <b> Location Data: </b><span>
                    When applicable, we may collect location information if enabled on your device or browser.
                    </span></h3>
                    <h3><b>2. How We Use Your Information </b></h3>
                    <p>
                    We use the collected information to improve our products, services, and customer experience. Specifically, we may use your information for:
                    </p>
                    <h3>2.1 <b>Order Processing and Fulfillment: </b><span>To process payments, fulfill orders, deliver products, and provide customer support.</span></h3>
                    <h3>2.2 <b>Improving Services:  </b><span>To understand your preferences, enhance our services, and develop new products and offerings.</span></h3>
                    <h3>2.3  <b>Marketing and Promotions:  </b><span>To send promotional emails and newsletters about new products, offers, and events, provided you have opted in to receive such communications.</span></h3>
                    <h3>2.4  <b>Legal Compliance:  </b><span>To comply with applicable laws, regulations, and contractual obligations.</span></h3>
                    <h3><b>3. How We Protect Your Information</b></h3>
                    <p>
                    We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. These measures include
                    </p>
                    <h3>3.1 <b>Data Encryption: </b><span>Encrypting sensitive data during transmission using SSL technology.</span></h3>
                    <h3>3.2 <b>Restricted Access: </b><span>Limiting access to personal data to authorized personnel who require it to perform their job functions.</span></h3>
                    <h3>3.3 <b>Regular Audits: </b><span>Conducting regular audits of our data handling practices to ensure security and compliance.</span></h3>
                    <h3><b>4. Sharing Your Information</b></h3>
                    <p>
                    We do not sell or rent your personal information to third parties. However, we may share your information with:
                    </p>
                    <h3>4.1 <b>Service Providers: </b>
                    <span>
                    Third-party vendors who assist with order processing, payment processing, shipping, marketing, and data analytics. These vendors are contractually obligated to protect your data and only use it as directed by us.
                    </span>
                    </h3>
                    <h3>4.2 <b>Legal Requirements: </b>
                    <span>
                    Law enforcement or other governmental agencies if required by law or to protect our legal rights.
                    </span>
                    </h3>
                    <h3>4.3 <b>Business Transfers: </b>
                    <span>
                    In the event of a merger, acquisition, or sale of our company, personal information may be transferred as part of the transaction.
                    </span>
                    </h3>
                    <h3><b>5. Your Rights and Choices </b></h3>
                    <p>
                    You have certain rights regarding your personal information, including:
                    </p>
                    <h3>5.1 <b>Access and Correction: </b>
                    <span>
                    You may access or update your personal information by contacting us via the First Call Coffee Company website in the Contact US link.
                    </span>
                    </h3>
                    <h3>5.2 <b>Opt-Out of Marketing Communications: </b>
                    <span>
                    You may unsubscribe from marketing emails by clicking the “unsubscribe” link in the email or contacting us directly.
                    </span>
                    </h3>
                    <h3>5.3 <b>Data Deletion: </b>
                    <span>
                    You may request that we delete your personal data where it is no longer necessary for us to retain it.
                    </span>
                    </h3>
                    <h3>5.4 <b>Cookie Preferences: </b>
                    <span>
                    Most browsers allow you to control or block cookies. Disabling cookies may impact certain functionalities of our website.
                    </span>
                    </h3>
                    <h3><b>6. Cookies and Tracking Technologies </b></h3>
                    <p>
                    We use cookies and similar technologies to collect information about how you interact with our website. This helps us understand user behavior and improve our website functionality.
                    </p>
                    <h3>6.1 <b>Types of Cookies Used: </b>
                    <span>
                    We use session cookies, which expire when you close your browser, and persistent cookies, which remain on your device for a set period or until deleted.
                    </span>
                    </h3>
                    <h3>6.2 <b>Third-Party Analytics: </b>
                    <span>
                    We may use third-party analytics services to analyze web traffic. These providers may collect information using cookies or similar tracking technologies.
                    </span>
                    </h3>
                    <h3><b>7. Children’s Privacy</b></h3>
                    <p>
                    Our website and services are not directed toward children under 13, and we do not knowingly collect personal information from children. If we become aware of any information collected from a child under 13, we will delete it immediately.
                    </p>
                    <h3><b>8. Changes to This Privacy Policy</b></h3>
                    <p>
                    We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the “Effective Date” at the top will be updated accordingly. We encourage you to review this policy periodically for any updates.
                    </p>
                    <h3><b>9. Contact Us</b></h3>
                    <p>
                    If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please contact us at <a href='mailto:support@firstcallcoffee.com'>support@firstcallcoffee.com</a>
                    </p>
                    <h3><b>First Call Coffee Company, LLC</b></h3>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
