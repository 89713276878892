import React from 'react'

export const SecondBanner = () => {
  return (
    <>
    <section className="main SecondAboutSec">
        <div className="container my-4">
            <div className="row align-items-center">
                <div className="col-md-6">
                    <h2>SUICIDE PREVENTION</h2>
                    <p>
                    First Call Coffee Company (FCCC) founded by a veteran is a purpose driven company to support veterans’ mental health. FCCC is committed to reduce the rate of suicide in veterans. An astonishing average of 20 veterans commit suicide every day. FCCC will provide your donations to a mental health organization of your preference from 3 selected organizations including the Wounded Warrior Project, Headstrong Project, and K9s for Warriors. FCCC will donate a percentage of the earnings towards these veteran mental health organizations. We can make a difference!
                    </p>
                </div>
                <div className='col-md-6'>
                    <img style={{objectFit:'contain',width:'100%'}} src={require('../assets/images/army.png')}  alt=''/>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
