import React from 'react'

export const SecondBanner = () => {
  return (
    <>
    <section className="main SecondAboutSec">
        <div className="container my-4">
            <div className="row align-items-center">
                <div className="col-md-12">
                    <h2>Terms and Conditions</h2>
                    <h3><b>Effective Date: </b>11/10/24</h3>
                    <p>
                    Welcome to First Call Coffee Company, LLC! By placing an order on our website or purchasing our products, you agree to comply with and be bound by the following terms and conditions. Please review these carefully before making a purchase.
                    </p>
                    <h3><b>1. General Terms</b></h3>
                    <h3>1.1 <span>
                    These Terms and Conditions apply to all purchases made from First Call Coffee Company, whether online, by phone, or at a physical location. 
                    </span></h3>
                    <h3>1.2 <span>
                    By placing an order with First Call Coffee Company, you agree to these Terms and Conditions in full.
                    </span></h3>
                    
                    <h3><b>2. Ordering and Payment</b></h3>
                    <h3>2.1 <b>Order Confirmation: </b><span>
                    Once you place an order, you will receive a confirmation email detailing the items ordered, pricing, and applicable shipping costs.
                    </span></h3>
                    <h3>2.2 <b>Payment Terms: </b><span>Payment is due at the time of purchase. We accept credit card, PayPal, Apple Pay, and bank transfers. All prices are listed in US dollars and exclude any applicable taxes unless otherwise stated.</span></h3>
                    <h3>2.3 <b>Changes to Orders:  </b><span>Changes to orders are accepted within 24 hours after order placement. Please contact via the First Call Coffee Company website in the Contact US link to request changes.</span></h3>
                    <h3>2.4  <b>Cancellation Policy:  </b><span>Orders can be canceled within 24 hours of placement. Cancellations requested after this time may not be guaranteed due to roasting and shipping schedules.</span></h3>
                    <h3><b>3. Shipping and Delivery</b></h3>
                    <h3>3.1 <b>Processing Time: </b><span>Orders are typically processed within 5 business days of payment confirmation. Delivery times vary by location.</span></h3>
                    <h3>3.2 <b>Shipping Costs: </b><span>Shipping costs are calculated at checkout and vary based on destination, weight, and shipping method.</span></h3>
                    <h3>3.3 <b>Lost or Delayed Shipments: </b><span>We strive to ensure timely delivery. However, First Call Coffee Company is not liable for delays caused by third-party shipping providers. If a package is lost or delayed, please contact our customer service team for assistance.</span></h3>
                    <h3><b>4. Return and Refund Policy</b></h3>
                    <h3>4.1 <b>Quality Guarantee: </b>
                    <span>
                    We stand behind the quality of our coffee. If you are not satisfied with your purchase, please contact us within 30 days of receiving the product.
                    </span>
                    </h3>
                    <h3>4.2 <b>Refunds and Replacements: </b>
                    <span>
                    Due to the perishable nature of coffee, refunds or replacements are issued if there is a quality issue with the product. Contact us within 7 days of receiving your order to arrange for a replacement or refund.
                    </span>
                    </h3>
                    <h3><b>5. Subscription Terms (if applicable)</b></h3>
                    
                    <h3>5.1 <b>Subscription Cancellation: </b>
                    <span>
                    You may cancel your subscription at any time by accessing your account or contacting customer service. Cancellation must be made at least 5 days before your next scheduled shipment to avoid being charged.
                    </span>
                    </h3>
                    <h3>5.2 <b>Subscription Modifications: </b>
                    <span>
                    Subscribers can update their preferences, including coffee selection and delivery frequency, through their account settings.
                    </span>
                    </h3>
                    <h3>5.3 <b>Pausing or Skipping a Delivery: </b>
                    <span>
                    Subscribers can pause or skip a delivery by contacting us or updating their account settings at least 5 days before the next scheduled shipment.
                    </span>
                    </h3>
                    <h3><b>6. Liability Disclaimer </b></h3>
                    
                    <h3>6.1 <span>
                    First Call Coffee Company is not liable for any indirect, incidental, or consequential damages that may arise from the use of our products. Our liability is limited to the amount paid for the products purchased.
                    </span>
                    </h3>
                    <h3>6.2  <span>
                    Customers should ensure they are not allergic to coffee or any ingredients in the products they purchase.
                    </span>
                    </h3>
                    <h3><b>7. Intellectual Property Rights</b></h3>
                    <h3>7.1 <span>
                    All content on our website, including but not limited to text, graphics, logos, images, and software, is the property of First Call Coffee Company and is protected by copyright, trademark, and other intellectual property laws.
                    </span>
                    </h3>
                    <h3>7.2 <span>
                    Customers may not use our intellectual property without express written permission.
                    </span>
                    </h3>
                    <h3><b>8. Privacy Policy</b></h3>
                    <h3>8.1 <b>Data Collection: </b>
                    <span>
                    We collect and process personal data in accordance with our Privacy Policy, which can be found here <a href='https://firstcallcoffee.com/privacy-policy'>https://firstcallcoffee.com/privacy-policy </a>.
                    </span>
                    </h3>
                    <h3>8.2 <b>Data Security: </b>
                    <span>
                    We employ reasonable security measures to protect your personal information.
                    </span>
                    </h3>
                    <h3><b>9. Governing Law</b></h3>
                    <p>
                    These Terms and Conditions shall be governed by and construed in accordance with the laws of United States / Texas, without regard to its conflict of law principles. Any disputes arising from these terms shall be resolved in the courts of United States / Texas.
                    </p>
                    <h3> <b>10. Changes to Terms</b></h3>
                    <p>
                    First Call Coffee Company reserves the right to update or modify these Terms and Conditions at any time. Any changes will be posted on our website and will apply to orders placed after the effective date of the revised terms.
                    </p>
                   <hr/>
                   <h3>
                   For any questions or concerns regarding these Terms and Conditions, please contact our customer service team at <a href='mailto:support@firstcallcoffee.com'>support@firstcallcoffee.com.</a>
                   </h3>
                    <h3><b>First Call Coffee Company, LLC</b></h3>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
